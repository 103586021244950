/*Portal Logo*/
#portal-logo {
  display: inline-block;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

/*Plone breadcrumb*/
#portal-breadcrumbs {
  background-color: transparent;
  border-top: 1px solid $gray-200;

  @include media-breakpoint-down(md) {
    display: none;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 1rem;
    padding-top: 1rem;

    .breadcrumb {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      background-color: transparent;
    }
  }
}

/*Above Content Shadow*/
#above-content-wrapper {
  //box-shadow: $box-shadow-inset;
  box-shadow: inset 0 3px 3px rgb(0 0 0 / 3%);
}

/* Site Nav Anpassungen */

@include media-breakpoint-up(lg) {
  #mainnavigation,
  #portal-globalnav-wrapper,
  #portal-globalnav-wrapper > div,
  #portal-globalnav-collapse,
  #portal-globalnav,
  #portal-globalnav > li {
    height: 100%;
  }

  #portal-globalnav-wrapper {
    &.navbar-expand-lg{
      .offcanvas{
        &.offcanvas-end {
          height:100% !important;
        }
        .offcanvas-body{
          flex-grow: 1;
        }
      }
    }

    #portal-globalnav {
      > li {
        align-self: center !important;
        border-left: 1px solid $gray-200;
        display:flex;
        background-color:white;

        &:last-child {
          border-right: 1px solid $gray-200;
        }

        > a {
          display: flex;
          align-items: center;
          align-self: center;
          padding-left: 2rem;
          padding-right: 2rem;
          padding-top: 0;
          padding-bottom: 0;
          text-align: center;
          color:#6c757d;
          height:100%;
          position:relative;

          &:after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 6px;
            background: white;
          }

          &:hover {
            &:after {
              background: $blue;
            }
          }
        }

        > ul {
          li,
          li a {
            background-color: $gray-200 !important;
          }

          li {
            label:after,
            a {
              color: $blue;
            }
          }
        }

        > ul{
          position:absolute;
          top:92px;
        }

        > label{
          display:none
        }
      }
    }
  }
}

/*Tools Header*/
#tools-header {
  background-color: $blue;
  color: #ccc;

  /*Search for Flyout*/
  a {
    color: white;
    text-decoration: none;
    font-weight: $font-weight-light;
    transition: padding-top 0.25s ease-out, padding-bottom 0.25s ease-out;
    -webkit-appearance: none;
    -moz-appearance: none;

    > span {
      font-size: 85%;
      align-self: center !important;
    }

    &:hover {
      > span {
        text-decoration: underline;
      }
    }

    &:before,
    &:after {
      font-family: tud-icons !important;
      font-style: normal;
      font-weight: 400;
      align-self: center !important;
      margin-left: 0.3rem;
    }

    &.search {
      &:after {
        content: "";
      }

      &:before {
        content: "";
        color: #333;
        background-color: $gray-200;
        transition: all 0.4s ease-in-out;
      }
    }

    &.login {
      &:after {
        content: "";
        transform: rotate(90deg);
      }
    }

    &[aria-expanded="false"] {
      padding-top: 1rem;
      padding-bottom: 1rem;

      &:before {
        display: none;
        padding: 0rem;
        transition: all 0.25s ease-in-out;
      }

      &:after {
        display: block;
      }

      > span {
        display: inline-block;
      }
    }

    &[aria-expanded="true"] {
      padding-top: 0rem;
      padding-bottom: 0rem;

      &:before {
        display: block;
        padding: 1rem;
      }

      &:after {
        display: none;
      }

      > span {
        display: none;
      }
    }
  }

  /*AnonTools for Login/Logout*/
  #portal-anontools {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex !important;

    ul {
      margin: 0;
      padding: 0;
    }

    #personaltools-login {
      &:after {
        content: "";
        transform: rotate(90deg);
      }
    }
  }
}

/*Tile Panel View*/
.tile-link {
  display: block;
  overflow: hidden;

  img {
    transform: scale(1);
    opacity: 1;
    transition: all 200ms ease;
    transition-property: opacity, transform;

    &:hover {
      transform: scale(1.05);
      opacity: 0.7;
    }
  }

  span {
    font-weight: $font-weight-light;
    @include font-size(1.55rem);
    color: $blue;
    background-color: white;
    text-transform: uppercase;
    display: inline-block;
    padding: 0.1rem 0.3rem;
    margin-bottom: 0.5rem;
  }

  button {
    &:before {
      content: "";
      font-family: tud-icons !important;
      font-style: normal;
      font-weight: 400;
      position: relative;
      top: 1px;
      left: -5px;
    }
  }
}

/*FlyOut Bar TOP*/
#flyout-bar {
  font-size: $font-size-sm;
  background-color: $gray-200;

  .searchButton {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    .searchButton {
      width: auto;
    }
  }
}

/*webmarket font*/
.highlight-font {
  font-family: "MarketWeb";
}


/*pat-structure folder listing*/
.pat-structure,
.statistics {
  table {

    tr{
      &.itemRow{

        &.folder{
          .title{
            a {
              color: unset;
            }
          }
        }

        td.actionmenu-container{
          .btn{
            color:unset
          }
        }
      }
    }


    tr {
      &.state-private {
        td {
          &.review_state {
            color: $state-private-color;
          }
        }
      }

      &.state-internally_published{
        td {
          &.review_state {
            color: $state-internally-published-color;
          }
        }
      }

      &.state-restricted_published {
        td {
          &.review_state {
            color: $state-restricted_published-color;
          }
        }
      }

      &.state-external {
        td {
          &.review_state {
            color: $state-external-color;
          }
        }
      }

      &.state-review {
        td {
          &.review_state {
            color: $state-review-color;
          }
        }
      }

      &.state-discard {
        td {
          &.review_state {
            color: $state-discard-color;
          }
        }
      }

      &.state-protected {
        td {
          &.review_state {
            color: $state-protected-color;
          }
        }
      }

      td {
        &.actionmenu-container {
          a {
            color: $table-color;
          }
        }
      }
    }
  }
}

/*Folder Listing View*/
.template-listing_view,
.template-extended_collection_view {
  .entries {
    .list-group-item {
      border-right: 5px solid;

      &.state-private {
        border-right-color: $state-private-color;
      }

      &.state-restricted_published {
        border-right-color: $state-restricted_published-color;
      }

      &.state-internally_published {
        border-right: 1px solid $list-group-border-color;
      }

      &.state-external {
        border-right-color: $state-external-color;
      }

      &.state-review {
        border-right-color: $state-review-color;
      }

      &.state-discard {
        border-right-color: $state-discard-color;
      }

      &.state-protected {
        border-right-color: $state-protected-color;
      }
    }
  }
}

/*Prev-Next Navigation*/
#section-next-prev {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

/*DX Edit Forms*/
/*Description Textarea*/
textarea[name="form.widgets.IBasic.description"] {
  height: 200px;
}

/*taxonomy ordered selection field*/
.ordered-selection-field {
  width: 100%;
  display: block;

  tbody {
    width: 100%;
    display: flex;
    tr{
      display: block;
      td{
        display: block;
      }
    }
  }

  tr:nth-of-type(1),
  tr:nth-of-type(3) {
    width: 35%;
    flex-shrink: 1;
    flex-grow:2;
  }
  tr:nth-of-type(2){
    flex-shrink: 2;
    flex-grow:1
  }

  button{
    transform: rotate(-90deg);
  }

  .list-field {
    height: 240px;

    option {
      margin-left: 1rem;

      &[value="1"],
      &[value="2"],
      &[value="3"],
      &[value="4"],
      &[value="5"] {
        margin-left: 0rem;
      }
    }
  }
}

/*Pagination datatables*/
.paginate_button {
  padding: 0.5rem 1rem;
  border: solid #ddd;
  border-width: 1px 1px 1px 0;

  &.active {
    background: #eee;
  }

  &:hover {
    background: #eee;
    cursor: pointer;

    &.disabled {
      cursor: auto;
      background: #fff;

      a {
        color: #777;
        cursor: not-allowed;
      }
    }
  }

  &:first-child {
    border-left-width: 1px;
    border-radius: 3px 0 0 3px;
  }

  &:last-child {
    border-left-width: 0px;
    border-radius: 0 3px 3px 0;
  }

  a {
    text-decoration: none;
  }
}

/*Offset Canvas Portal Column Two*/
@include media-breakpoint-down(lg) {
  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80%;
    z-index: 1200;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
    background-color: white;
  }

  #mobile-offcanvas-close-button {
    display: none;
    visibility: hidden;
  }

  .mobile-offcanvas.show {
    #mobile-offcanvas-close-button {
      display: inline;
      visibility: visible;
    }
  }

  #bt-offcanvas-collectionfilter {
    &[data-eportal-filter-state="open"] {
      position: fixed;
      z-index: 12000;
      top: 0;
      right: 0;
    }
  }
}

/*Edit DX Forms*/
.field.error.empty {
  .ordered-selection-field {
    border: 1px solid red;
  }
}

/*Webkit Fixes*/
a {
  &[type="button"] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

/*Override Button Border Radius*/
.btn-group-sm > .btn,
.btn-sm {
  border-radius: 0;
}

/*Favorites List*/
.user-fav-list {
  button {
    &:not(.collapsed) {
      &::after {
        background-image: escape-svg($accordion-white-button-active-icon);
        transform: $accordion-icon-transform;
      }
    }

    &::after {
      flex-shrink: 0;
      width: $accordion-icon-width;
      height: $accordion-icon-width;
      margin-left: auto;
      content: "";
      background-image: escape-svg($accordion-white-button-icon);
      background-repeat: no-repeat;
      background-size: $accordion-icon-width;
      @include transition($accordion-icon-transition);
    }
  }
}

/*Widgets*/

/*Select2 Widget allgemein*/
.select2-container-multi{
  .select2-choices{
    background-image:none;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;;
    .select2-search-choice{
      line-height: 1.2rem !important;
    }
  }
}

div{
  &#formfield-form-widgets-IOwnership-creators,
  &#formfield-form-widgets-IOwnership-contributors{
    .select2-container.select2-container-multi{
      display:block;
    }
  }
  &#formfield-form-widgets-structureareas{
    #form-widgets-structureareas{
      &.d-block{
        display:none !important;
      }
    }
  }
}


/*Content Area*/

@include media-breakpoint-up(md) {
  body {
    &.col-content.frontend,
    &.col-content.template-my-favorites,
    &.col-content.template-computerpool-card,
    &.col-content.template-selftesting,
    &.col-content.template-add-selftest-result,
    &.col-content.template-personal-information,
    &.col-content.template-view-as-student {
      main.row {
        justify-content: space-around;

        #portal-column-content {
          max-width: 60%;
        }
      }
    }

    &.col-content.frontend.template-document_blue_highlight_view {
      main.row {
        justify-content: space-around;

        #portal-column-content {
          max-width: 80%;
        }
      }
    }

    &.col-content.frontend.template-tiles_view {
      main.row {
        justify-content: unset;

        #portal-column-content {
          max-width: 100%;
        }
      }
    }

    &.col-content.template-view-as-student.template-tiles_view {
      main.row {
        justify-content: unset;

        #portal-column-content {
          max-width: 100%;
        }
      }
    }
  }
}

/*Content elements*/
h1,
h2,
h3 {
  color: rgb(51, 51, 51);
}

h1 {
  @include font-size(1.55rem);
  text-transform: uppercase;

  &.blue {
    > span {
      color: white;
      background-color: $blue;
      display: inline-block;
      padding: 0.05rem 0.3rem;
    }
  }
}

h2 {
  @include font-size(1.35rem);
}

h3 {
  @include font-size(1.15rem);
}

h4 {
  @include font-size(1rem);
}

// fluid images
.image-responsive {
  @include img-fluid();
}

//text classes for workflow states

.text-state-private {
  color: $state-private-color;
}

.text-state-restricted_published {
  color: $state-restricted_published-color;
}

.text-state-external {
  color: $state-external-color;
}

.text-state-review {
  color: $state-review-color;
}

.text-state-discard {
  color: $state-discard-color;
}

.text-state-protected {
  color: $state-protected-color;
}


p.callout {
  background: #eee;
  padding: 1em;
  border-left: 1em solid #ccc;
  clear: both;
}