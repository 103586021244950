.pat-autotoc.autotabs {
    .autotoc-nav {
        margin-bottom: $spacer;
    }
}


label .required:after,
label.required:after {
    color: $danger;
    content: '•';
    font-size:$font-size-lg;
    line-height: 1;
}

/* invalid-feedback */
.field.error .invalid-feedback {
    display:block;
}

.form-group {
    .select2-container {
        display:block;
    }
}

.formControls {
    margin-top: $spacer * 1.5;
    margin-bottom: $spacer * 0.5;
}

/* Contact Behavior*/
#formfield-form-widgets-IContactPersonBehavior-contacts{

    div.pat-select2{
        ul.select2-choices{
            border:0;
            background-image:none
        }
    }

    .select2-container-multi .select2-choices .select2-search-choice{
        line-height: 1rem;
    }
    select.form-select.pat-select2.d-block{
        display: none !important;
    }
}

/* ICategorization language select2 widget*/
#formfield-form-widgets-ICategorization-language{
    #form-widgets-ICategorization-language{
       display:none!important;
    }
}

/* ICategorization subjects select2 widget*/
#formfield-form-widgets-ICategorization-subjects{
   .select2-container{
    display:block;
   }
}

/* relateditems widget */
.pattern-relateditems-result {
    .pattern-relateditems-result-browse-wrapper {
        .pattern-relateditems-result-browse {
            font-size: $font-size-base;
        }
    }
}

.select2-results .select2-highlighted {
    color:$link-hover-color;
}

.pattern-relateditems-item-title,
.pattern-relateditems-result-title {
    &, &[class*="contenttype-"]:before {
        font-size: $font-size-lg;
    }
}
